import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const RegistrationConfirmation = () => {
  return (
    <>
      <GatsbySeo title="Registration Confirmation" />

      <section className="section">
        <div className="container is-max-desktop">
          <h2 className="title has-text-midnightBlue is-spaced is-size-4-mobile">
            Thanks for registering! Next Steps:
          </h2>
          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Collect your sample</h3>
          <div className="content">
            <p>Follow the instructions found in your test kit to collect your sample.</p>

            <p>
              This video has some useful tips for taking a nose and throat swab to test for coronavirus. Please note
              that the packaging and labelling are different for your kit but the actual sampling process is the same:
            </p>

            <iframe
              title="Instruction video (Youtube)"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/8lo6g-TYZ-c"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Complete the request form</h3>
          <div className="content">
            <p>
              You must write your full name and date of birth on the request form as well as on the sample collection
              tube. Please make sure to write legibly so the lab staff can read your writing. Without a labelled sample
              and completed request form, the lab cannot analyse your sample.
            </p>
          </div>
          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Return your sample</h3>

          <div className="content">
            <p>
              You can either drop your sample off directly at the lab or one of the drop-off points or return it by
              post.
            </p>
            <p className="has-text-weight-bold">Drop-off in person:</p>
            <p>
              We use The Doctors Laboratory in London to process travel samples and their laboratory is open 24/7
              including public holidays to receive samples in person or by courier if you prefer.
            </p>

            <address className="is-size-6 ml-5 mb-3">
              The Doctors Laboratory <br />
              The Halo Building <br />
              1 Mabledon Place <br />
              London <br />
              WC1H 9AX
            </address>

            <p>Alternatively, samples can also be dropped off at the Manchester lab:</p>
            <address className="is-size-6 ml-5 mb-3">
              The Doctors Laboratory
              <br />
              Regents Place
              <br />
              4 Windsor Street
              <br />
              Salford
              <br />
              M5 4HB
            </address>

            <p>
              The Manchester lab couriers samples to London daily. Drop off your sample by 14:00 or 21:00 on Mon-Fri and
              by 21:00 on Sat & Sun
            </p>

            <p className="has-text-weight-bold">Post back to the lab</p>
            <p>
              Place your sample and the request form in the prepaid Royal Mail Tracked 24 envelope. Keep a note of your
              tracking number. Post your sample to the lab as soon as possible after taking it. We strongly recommend
              posting your sample in a Royal Mail Priority postbox. Use the{' '}
              <a href="https://www.royalmail.com/services-near-you" target="_blank" rel="noreferrer noopener">
                Royal Mail service finder{' '}
              </a>
              to check the location of your nearest priority postbox.
            </p>
            <p>
              Please be aware that Royal Mail does not collect or deliver to the lab on Sundays and public holidays.
              Furthermore, although the vast majority of samples sent Tracked 24 arrive at the lab the day after
              sending, the service is not guaranteed and samples occassionally take longer.
            </p>
          </div>
          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Get your results</h3>
          <div className="content">
            <p>
              Your result will be available within 24 hours of our receiving your sample in the laboratory. We will
              email it to the address you verified with us.
            </p>
          </div>
          <hr />
          <p className="mt-3">
            <Link to="/register">Register another kit</Link>
          </p>
        </div>
      </section>
    </>
  )
}

export default RegistrationConfirmation
